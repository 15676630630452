import { gql } from '@apollo/client';

export const BROKER_QUERY = gql`
  query Broker($input: BrokerInput) {
    broker: getBroker(input: $input) {
      id
      name
      title
      email
      cellphone
      path
      helper
      properties {
        show
      }
      offices {
        list {
          title
          name
          urltag
          naering
        }
      }
      image {
        full
      }
      groupTypes {
        data {
          groups
          key
        }
      }
    }
  }
`;
