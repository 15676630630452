import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { useQuery } from '@apollo/client';
import { RouteComponentProps, useParams } from 'react-router';
import { animated } from 'react-spring';

import { Container } from '../../../layout/Container';
import { Section } from '../../../layout/Section';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { Button } from '../../../ui/button/Button';
import { ButtonGroupColumn } from '../../../ui/button/ButtonGroupColumn';

import { PropertyList } from '../../../partials/cluster/PropertyList';

import { EmailOldIcon } from '../../../ui/icons/Email';
import { Image as BrokerImage } from '../../../ui/broker/partials/Image';

import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { useScrollToElement } from '../../../../utils/hooks/useScrollToElement';

import { SharingVcard } from '../ui/SharingVcard';
import { Seo } from '../../../../utils/seo/Index';

import { replaceNaeringUrltag } from '../../../../utils/helpers/replaceNaeringUrltag';
import { ButtonBar } from '../../../layout/ButtonBar';
import NoMatch from '../../404Old';
import { PropertyListContextProvider } from '../../../../utils/context/PropertyListContext';
import { client } from '../../../../__graphql__/client';
import MeglerCv from '../../meglercv/MeglerOverview';
import {
  McvProfileQuery,
  McvProfileQueryVariables,
  UserValueType,
  EmployeeQuery,
  EmployeeQueryVariables,
  DepartmentTag
} from '../../../../__types__/generated/cluster';
import { BROKER_CV_LIVE_QUERY } from '../../../../../client/__graphql__/cluster/queries/profile/brokerCvLive';
import { EMPLOYEE_QUERY } from '../../../../../client/__graphql__/cluster/queries/employee/employee';
import { ENV_CONFIG } from '../../../../__config__/env';
import { usePageSeo } from '../../../../utils/hooks/usePageSeo';
import { formatPhone } from '../../../../utils/format/formatPhone';

//Legacy
import { BROKER_QUERY } from '../../../../../client/__graphql__/queries/broker';
import {
  Broker,
  BrokerVariables
} from '../../../../__types__/generated/Broker';

interface IRoute {
  path: string;
}

const Index: React.FC<RouteComponentProps<IRoute>> = ({
  match,
  location,
  history
}) => {
  const params = useParams<{ path: string }>();
  const seo = usePageSeo([params?.path]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const { data: legacy, loading: legacyLoading } = useQuery<
    Broker,
    BrokerVariables
  >(BROKER_QUERY, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        path: params?.path
      }
    }
  });

  const { data, loading, error } = useQuery<
    EmployeeQuery,
    EmployeeQueryVariables
  >(EMPLOYEE_QUERY, {
    fetchPolicy: 'network-only',
    ssr: true,
    skip: !params?.path,
    variables: {
      input: {
        brandId: ENV_CONFIG?.BRAND_ID,
        employeeAlias: params?.path
      }
    }
  });

  const { data: cvData, loading: cvLoading } = useQuery<
    McvProfileQuery,
    McvProfileQueryVariables
  >(BROKER_CV_LIVE_QUERY, {
    fetchPolicy: 'network-only',
    ssr: true,
    skip: !params?.path,
    variables: {
      input: {
        brandId: ENV_CONFIG?.BRAND_ID,
        value: params?.path,
        type: UserValueType.Alias
      }
    }
  });

  const propertyListRef = React.useRef(null);
  const [showSold, setShowSold] = React.useState(false);
  const { scrollTo } = useScrollToElement();

  const { fadeIn } = useFadeTransition({
    //loaded: !loading && !profileLoading && data?.broker?.name ? true : false
    loaded: !loading && !cvLoading && !seo?.loading && !legacyLoading
  });

  if (error?.graphQLErrors?.length) {
    return <NoMatch />;
  }

  if (
    cvData?.mcvProfile?.profile?._id &&
    data?.employee?.employeeAlias &&
    !cvLoading &&
    !loading
  ) {
    return (
      <>
        <Seo
          title={`${data?.employee?.name} - PrivatMegleren ${
            data?.employee?.departments?.find((e) => e?.name)?.name ?? ``
          }`}
          description={data?.employee?.title || ''}
          image={
            cvData?.mcvProfile?.profile?.profileImageUrl ||
            data?.employee?.image?.url ||
            ''
          }
          url={location.pathname}
          canonical={`https://privatmegleren.no${location.pathname}`}
        />
        <Section className="center" style={{ padding: 0, ...fadeIn }}>
          <MeglerCv
            profile={cvData?.mcvProfile?.profile}
            employee={data?.employee}
          />
        </Section>
      </>
    );
  }

  return (
    <>
      {legacy?.broker?.properties?.show ? <GlobalFooterStyles /> : null}
      <Seo
        title={`${data?.employee?.name} - PrivatMegleren ${
          data?.employee?.departments?.find((e) => e?.name)?.name ?? ``
        }`}
        description={data?.employee?.title || ''}
        image={data?.employee?.image?.url || ''}
        url={location.pathname}
        canonical={`https://privatmegleren.no${location.pathname}`}
      />
      <Container style={{ paddingTop: '100px' }}>
        <Section className="center" style={fadeIn}>
          <BrokerImage url={data?.employee?.image?.url ?? ``} size="large" />
          <BrokerName>{data?.employee?.name}</BrokerName>
          <BrokerTitle>{data?.employee?.title}</BrokerTitle>
          <BrokerOfficeList>
            {data?.employee?.departments?.length
              ? data?.employee?.departments?.map((item, index: number) => {
                  if (item?.tags?.includes(DepartmentTag.Commercial)) {
                    return (
                      <BrokerOfficeListItemAnchor
                        rel="noopener"
                        key={`broker_office_${index}`}
                        href={
                          item.alias
                            ? `https://privatmegleren.no/naering/${replaceNaeringUrltag(
                                item.alias
                              )}/kontakt`
                            : undefined
                        }
                      >
                        PrivatMegleren {item?.name}
                      </BrokerOfficeListItemAnchor>
                    );
                  } else {
                    return (
                      <BrokerOfficeListItem
                        key={`broker_office_${index}`}
                        to={item?.alias ? `/${item.alias}` : ``}
                      >
                        PrivatMegleren {item?.name}
                      </BrokerOfficeListItem>
                    );
                  }
                })
              : null}
          </BrokerOfficeList>
          <OfficeContactInfo>
            <OfficePhone>
              <a href={`tel:${data?.employee?.mobilePhone?.replace(/ /g, '')}`}>
                {formatPhone(data?.employee?.mobilePhone)}
              </a>
            </OfficePhone>
            <OfficeEmail className="email">
              <Link to={`/megler/${match.params.path}/kontakt`}>
                <EmailOldIcon />
              </Link>
            </OfficeEmail>
          </OfficeContactInfo>
          <SharingVcard
            name={data?.employee?.name ?? ``}
            title={data?.employee?.title ?? ``}
            path={match.params.path}
            showTitles
          />
        </Section>
      </Container>
      <ButtonBar style={fadeIn}>
        <Container>
          <ButtonGroup className="wrap-items center">
            {legacy?.broker?.helper === false ? (
              <ButtonGroupColumn className="space-bottom col-3">
                <Button
                  type="primary"
                  colorTheme="gold"
                  fullWidth={true}
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      const url = new URL(window.location.href);
                      window.location.href = `${url.protocol}//${url.host}/megler/${match.params.path}/verdivurdering-selge-eiendom`;
                    }
                  }}
                >
                  Selge eiendom?
                </Button>
              </ButtonGroupColumn>
            ) : null}
            {legacy?.broker?.properties?.show &&
            legacy?.broker?.helper === false ? (
              <ButtonGroupColumn className="space-bottom col-3">
                <Button
                  type="primary"
                  colorTheme="gray"
                  fullWidth={true}
                  onClick={() => {
                    setShowSold(false);
                    if (typeof scrollTo === 'function') {
                      scrollTo({
                        ref: propertyListRef,
                        distanceTop: 50,
                        behaviour: 'smooth'
                      });
                    }
                  }}
                >
                  Til salgs
                </Button>
              </ButtonGroupColumn>
            ) : null}
            {legacy?.broker?.properties?.show &&
            legacy?.broker?.helper === false ? (
              <ButtonGroupColumn className="space-bottom col-3">
                <Button
                  type="primary"
                  colorTheme="gray"
                  fullWidth={true}
                  onClick={() => {
                    setShowSold(true);
                    if (typeof scrollTo === 'function') {
                      scrollTo({
                        ref: propertyListRef,
                        distanceTop: 50,
                        behaviour: 'smooth'
                      });
                    }
                  }}
                >
                  Solgt
                </Button>
              </ButtonGroupColumn>
            ) : null}
          </ButtonGroup>
        </Container>
      </ButtonBar>
      {legacy?.broker?.properties?.show && data?.employee?.employeeAlias ? (
        <Container style={{ minHeight: '500px' }}>
          <Section className="center no-padding-top" style={fadeIn}>
            <PropertyListContextProvider>
              <PropertyList
                type="broker"
                employeeAliases={data?.employee?.employeeAlias}
                departmentAliases={data?.employee?.departments
                  ?.filter((e) => e?.alias)
                  ?.map((e) => e?.alias)}
                showCheckboxes={
                  data?.employee?.departments?.find((e) => e?.alias)?.alias !==
                  'bergensentrum'
                }
                defaultSold={showSold}
                hideBorderTop={true}
                forwardRef={propertyListRef}
              />
            </PropertyListContextProvider>
          </Section>
        </Container>
      ) : null}
      <BackgroundLoad
        opacity={0.35}
        position="center bottom -170px"
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </>
  );
};

export default Index;

const GlobalFooterStyles = createGlobalStyle`
  .footer {
    margin-top: 0;
  }
`;

const BrokerOfficeList = styled.ul`
  padding: 0;
  margin: 10px 0 10px 0;
  list-style: none;
  text-align: center;
`;

const BrokerOfficeListItemAnchor = styled.a`
  display: block;
  margin-bottom: 10px;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BrokerOfficeListItem = styled(Link)`
  display: block;
  margin-bottom: 10px;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BrokerName = styled.h1`
  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;
`;

const BrokerTitle = styled.p`
  margin: 0;
  color: white;
  text-align: center;
  line-height: 1.5625rem;
`;

const OfficeContactInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1.25rem 0;
`;

const OfficePhone = styled.span`
  a {
    text-decoration: none;
  }
`;

const OfficeEmail = styled.span`
  margin-left: 1.25rem;
`;
