import { gql } from '@apollo/client';

export const PAGE_SEO_QUERY = gql`
  query PageSeo($input: PageInput!) {
    layout {
      seo(input: $input) {
        title
        description
      }
    }
  }
`;
