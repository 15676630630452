import * as React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

const StyledButtonBar = styled(animated.div)`
  background-color: ${({ theme }) => theme.colors.body};
  padding: 2rem 0 1rem 0;
  width: 100%;
  bottom: 0;
  &.office {
    @media all and (max-width: 600px) {
      padding: 0;
    }
  }
`;

interface IButtonBar extends React.HTMLAttributes<HTMLElement> {}

export const ButtonBar: React.FC<IButtonBar> = (props) => {
  return <StyledButtonBar {...props} />;
};
