interface IArgs {
  streetAddress?: string | null;
  zipCode?: string | null;
  postPlace?: string | null;
}

export const formatAddress = (args: IArgs) => {
  const { streetAddress = null, zipCode = null, postPlace = null } = args;
  if (!streetAddress?.length && !zipCode?.length && !postPlace?.length)
    return null;
  const end =
    zipCode || postPlace ? `${zipCode ?? ``} ${postPlace ?? ``}`?.trim() : null;
  if (!streetAddress?.trim()?.length) return end;
  if (!end?.length) return streetAddress?.trim();
  return `${streetAddress?.trim()}, ${end}`;
};
