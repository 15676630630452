import { gql } from '@apollo/client';

export const DEPARTMENT_QUERY = gql`
  query Department($input: DepartmentInput!) {
    department(input: $input) {
      alias
      brandId
      email
      logo
      legalName
      employees {
        about
        brandId
        email
        employeeAlias
        employeeId
        image {
          url(size: Medium)
        }
        mobilePhone
        name
        role
        title
        workPhone
      }
      fax
      name
      orgNumber
      phone
      postalAddress
      postalPlace
      postalZip
      referenceUrls {
        valuationUrl
        departmentUrl
        pricelistUrl
      }
      role
      shortName
      texts {
        ... on DepartmentTextsDefault {
          about
          area
          intro
        }
      }
      visitingAddress
      tags
    }
  }
`;
