import * as React from 'react';
import { useQuery } from '@apollo/client';
import {
  PageSeoQuery,
  PageSeoQueryVariables
} from '../../__types__/generated/cluster';
import { PAGE_SEO_QUERY } from '../../../client/__graphql__/cluster/queries/seo';

interface IArgs {
  route: string[];
}

export const usePageSeo = (route: string[]) => {
  return useQuery<PageSeoQuery, PageSeoQueryVariables>(PAGE_SEO_QUERY, {
    fetchPolicy: 'no-cache',
    ssr: true,
    context: {
      headers: {
        'x-product-identifier': 'privatmegleren:site:privatmegleren_no',
        ssr: true
      }
    },
    variables: { input: { query: { route } } }
  });
};
