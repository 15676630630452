import * as React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { animated } from 'react-spring';
import { Container } from '../../../layout/Container';
import { Section } from '../../../layout/Section';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { BrokerItem } from '../../../ui/broker/Item';
import { Button } from '../../../ui/button/Button';
import { ButtonGroupColumn } from '../../../ui/button/ButtonGroupColumn';
import { Logo } from '../../../ui/logo/office/Index';
import { Column } from '../../../layout/Column';

import { OFFICE_QUERY } from '../../../../../client/__graphql__/queries';

import { PropertyList } from '../../../partials/cluster/PropertyList';

import { EmailOldIcon } from '../../../ui/icons/Email';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { useScrollToElement } from '../../../../utils/hooks/useScrollToElement';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Seo } from '../../../../utils/seo/Index';
import NoMatch from '../../404Old';
import { ButtonBar } from '../../../layout/ButtonBar';
import { PropertyListContextProvider } from '../../../../utils/context/PropertyListContext';
import { DEPARTMENT_QUERY } from '../../../../../client/__graphql__/cluster/queries/department/department';
import {
  DepartmentQuery,
  DepartmentQueryVariables,
  DepartmentTag
} from '../../../../__types__/generated/cluster';
import { ENV_CONFIG } from '../../../../__config__/env';
import { client } from '../../../../__graphql__/client';
import { usePageSeo } from '../../../../utils/hooks/usePageSeo';
import { formatPhone } from '../../../../utils/format/formatPhone';
import { formatAddress } from '../../../../utils/format/formatAddress';

const Index: React.FC = () => {
  const params = useParams<{ urltag: string }>();
  const history = useHistory();
  const location = useLocation();
  const seo = usePageSeo([`kontor/${params?.urltag}`]);

  const brokerListRef = React.useRef(null);
  const propertyListRef = React.useRef(null);

  const { data, loading } = useQuery<DepartmentQuery, DepartmentQueryVariables>(
    DEPARTMENT_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        input: { brandId: ENV_CONFIG?.BRAND_ID, alias: params?.urltag }
      },
      onError: ({ graphQLErrors }) => {
        graphQLErrors?.map((item: any, index: number) => {
          if (item?.extensions?.code === '404') {
            history.replace(`/finner-ikke-side`);
          }
        });
      }
    }
  );

  const { data: legacy, loading: legacyLoading } = useQuery(OFFICE_QUERY, {
    fetchPolicy: 'no-cache',
    client: client,
    variables: {
      input: {
        urltag: params?.urltag
      }
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors?.map((item: any, index: number) => {
        if (item?.extensions?.code === '404') {
          history.replace(`/finner-ikke-side`);
        }
      });
    }
  });

  const { scrollTo } = useScrollToElement();

  const { fadeIn } = useFadeTransition({
    loaded:
      !loading &&
      !seo?.loading &&
      !legacyLoading &&
      (data?.department?.name ? true : false)
  });

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const getAlias = (alias: string) => {
    if (alias === 'renommefrysja') return 'renomme';
    if (alias === 'tjuvholmen') return 'alle';
    if (alias === 'manglerud') return 'panorama';
    return alias;
  };

  if (data?.department?.tags?.includes(DepartmentTag.Commercial)) {
    return <NoMatch />;
  }

  const address =
    formatAddress({
      streetAddress: data?.department?.visitingAddress,
      zipCode: data?.department?.postalZip,
      postPlace: data?.department?.postalPlace
    }) ?? ``;

  return (
    <>
      <Seo
        title={
          seo?.data?.layout?.seo?.title ??
          `PrivatMegleren - Eiendomsmegler ${data?.department?.name ?? ``}`
        }
        description={
          seo?.data?.layout?.seo?.description
            ? seo?.data?.layout?.seo?.description
            : address
        }
        image="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/Privatmegleren_still2_1-1.jpg"
        imageTitle="/PrivatMegleren-eiendomsmegler-kjøp-salg-eiendom-leilighet-hus-bolig"
        url={location.pathname}
        canonical={`https://privatmegleren.no${location.pathname}`}
      />
      <Container style={{ paddingTop: '100px' }}>
        <Section className="office center" style={fadeIn}>
          <Logo name={data?.department?.name} alt="bilde av logo" />
          <OfficeAddress dangerouslySetInnerHTML={{ __html: address }} />
          {data?.department?.postalAddress ? (
            <OfficeAddress
              dangerouslySetInnerHTML={{
                __html: data?.department?.postalAddress
              }}
            />
          ) : null}
          <OfficeContactInfo>
            <OfficePhone>
              <a href={`tel:${data?.department?.phone?.replace(/ /g, '')}`}>
                {formatPhone(data?.department?.phone)}
              </a>
            </OfficePhone>

            <OfficeEmail
              className={params?.urltag === 'hovedkontor' ? 'hovedkontor' : ''}
            >
              <a href={`https://privatmegleren.no/${params.urltag}/kontakt`}>
                <EmailOldIcon />
              </a>
            </OfficeEmail>
          </OfficeContactInfo>
          <OfficeText
            dangerouslySetInnerHTML={{
              __html: data?.department?.texts?.intro ?? ``
            }}
          />
        </Section>
      </Container>
      <ButtonBar className="office" style={fadeIn}>
        <Container>
          <ButtonGroup
            className={
              params?.urltag === 'hovedkontor'
                ? 'wrap-items center'
                : 'wrap-items'
            }
          >
            {params?.urltag !== 'hovedkontor' ? (
              <ButtonGroupColumn className="space-bottom col-3">
                <Button
                  type="primary"
                  colorTheme="gold"
                  fullWidth={true}
                  center={true}
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      const url = new URL(window.location.href);
                      window.location.href =
                        params.urltag !== 'hovedkontor'
                          ? `${url.protocol}//${url.host}/${params.urltag}/verdivurdering-selge-eiendom`
                          : `${url.protocol}//${url.host}/verdivurdering-selge-eiendom`;
                    }
                  }}
                >
                  Selge eiendom?
                </Button>
              </ButtonGroupColumn>
            ) : null}

            {params?.urltag !== 'hovedkontor' ? (
              <ButtonGroupColumn className="space-bottom col-3">
                <Button
                  type="primary"
                  colorTheme="gray"
                  fullWidth={true}
                  center={true}
                  onClick={() => {
                    if (typeof scrollTo === 'function') {
                      scrollTo({
                        ref: brokerListRef,
                        distanceTop: 150,
                        behaviour: 'smooth'
                      });
                    }
                  }}
                >
                  Våre meglere
                </Button>
              </ButtonGroupColumn>
            ) : null}
            {params?.urltag !== 'hovedkontor' ? (
              <ButtonGroupColumn
                className="space-bottom col-3"
                onClick={() => {
                  if (typeof scrollTo === 'function') {
                    scrollTo({
                      ref: propertyListRef ? propertyListRef : null,
                      distanceTop: 50,
                      behaviour: 'smooth'
                    });
                  }
                }}
              >
                <Button
                  type="primary"
                  colorTheme="gray"
                  fullWidth={true}
                  center={true}
                >
                  Våre eiendommer
                </Button>
              </ButtonGroupColumn>
            ) : null}
            {params?.urltag === 'dyve' ? (
              <ButtonGroupColumn className="space-bottom col-3">
                <Button
                  type="primary"
                  colorTheme="gray"
                  onClick={() => {
                    history.push(`/${params.urltag}/finansraadgivere`);
                  }}
                  fullWidth={true}
                  center={true}
                >
                  Finansrådgivere
                </Button>
              </ButtonGroupColumn>
            ) : null}

            <ButtonGroupColumn className=" space-bottom col-3">
              <Button
                type="primary"
                colorTheme="gray"
                fullWidth={true}
                onClick={() => {
                  history.push(`/${params.urltag}/om-oss`);
                }}
                center={true}
              >
                Om oss
              </Button>
            </ButtonGroupColumn>

            {legacy?.office?.news?.hasNews ? (
              <ButtonGroupColumn className="space-bottom col-3">
                <Button
                  type="primary"
                  colorTheme="gray"
                  onClick={() => {
                    if (params.urltag === 'panorama') {
                      history.push('/kampanje/panorama/alle');
                    } else {
                      history.push(`/${params.urltag}/nyheter`);
                    }
                  }}
                  fullWidth={true}
                  center={true}
                >
                  Nyheter
                </Button>
              </ButtonGroupColumn>
            ) : null}
            {legacy?.office?.hasReferences ? (
              <ButtonGroupColumn className="space-bottom col-3">
                <Button
                  type="primary"
                  colorTheme="gray"
                  onClick={() => {
                    history.push(`/${params.urltag}/referanser`);
                  }}
                  fullWidth={true}
                  center={true}
                >
                  Referanser
                </Button>
              </ButtonGroupColumn>
            ) : null}

            {params?.urltag === 'hovedkontor' ||
            params?.urltag === 'nyeboligeroslo' ? (
              <ButtonGroupColumn className="space-bottom col-3 last-child">
                <Button
                  type="primary"
                  colorTheme="gray"
                  fullWidth={true}
                  onClick={() => {
                    history.push(`/${params.urltag}/aapenhetsloven`);
                  }}
                  center={true}
                >
                  Åpenhetsloven
                </Button>
              </ButtonGroupColumn>
            ) : null}
          </ButtonGroup>
        </Container>
      </ButtonBar>
      <Container style={{ minHeight: '500px' }}>
        <Section className="brokerlist center" style={fadeIn}>
          <BrokersOutsideWrapper>
            <BrokersWrapper className="brokers-list" ref={brokerListRef}>
              {data?.department?.employees
                ?.filter((item) => !item?.title?.match(/(Finansiell|Finans)/))
                .map((item, index) => {
                  return (
                    <Column key={`broker_${index}`} className="col">
                      <BrokerItem
                        image={item?.image?.url}
                        name={item?.name}
                        showImage={params?.urltag !== 'hovedkontor'}
                        title={item?.title}
                        office={data?.department?.name}
                        phone={item?.mobilePhone}
                        path={
                          item?.employeeAlias
                            ? `/megler/${item?.employeeAlias}`
                            : ``
                        }
                      />
                    </Column>
                  );
                })}
            </BrokersWrapper>
          </BrokersOutsideWrapper>
          {params.urltag !== 'hovedkontor' && data?.department?.alias ? (
            <PropertyListContextProvider>
              <PropertyList
                type="office"
                departmentAliases={getAlias(params.urltag)}
                forwardRef={propertyListRef}
              />
            </PropertyListContextProvider>
          ) : null}
        </Section>
      </Container>
      <BackgroundLoad
        opacity={0.25}
        position="center"
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/falk_bakside2.jpg"
      />
    </>
  );
};

export default Index;

const OfficeText = styled.div`
  text-align: center;

  p {
    font-family: 'DomaineText-Regular', 'Times New Roman', serif;
    font-size: 1.1em;
  }
`;

const OfficeAddress = styled.div`
  color: white;
  text-align: center;
  line-height: 1.5625rem;
`;

const OfficeContactInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1.25rem 0 2rem 0;
`;

const OfficePhone = styled.span`
  a {
    text-decoration: none;
  }
`;

const OfficeEmail = styled.span`
  margin-left: 1.25rem;
  &.hovedkontor {
    margin-left: 0;
  }
`;

const BrokersOutsideWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const BrokersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @media all and (max-width: ${(props) => props.theme.mediaSizes.small}px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
  @media all and (min-width: ${(props) =>
      props.theme.mediaSizes.small}px) and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media all and (min-width: 800px) and (max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const MittBoligkjop: React.FC = () => {
  return (
    <StyledMittBoligkjop>
      <img
        src="https://cdn.reeltime.no/pm_assets/mitt-boligkjop/top.png"
        alt="logo"
      />
      <div className="link-wrapper">
        <a
          href="https://itunes.apple.com/no/app/mitt-boligkj%C3%B8p/id1328257724?mt=8"
          target="_blank"
        >
          <img
            src="https://cdn.reeltime.no/pm_assets/mitt-boligkjop/appstore.png"
            alt="logo"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=no.pm.boligkjop"
          target="_blank"
        >
          <img
            src="https://cdn.reeltime.no/pm_assets/mitt-boligkjop/googleplay.png"
            alt="logo"
          />
        </a>
      </div>
    </StyledMittBoligkjop>
  );
};

const StyledMittBoligkjop = styled.div`
  margin-top: 30px;
`;
